import React from "react"

import Layout from "../components/0_utilities/layout"
import SEO from "../components/0_utilities/seo"

import ResourcesImg from "../components/1_atoms/banner/img/img-resources"

const IndexPage = () => (
  <Layout image={<ResourcesImg />} pageTitle="Resources and FAQs">
    <SEO
      title="Resources and FAQs"
      description="How often should a piano be tuned?"
    />
    <h2>How often should a piano be tuned?</h2>

    <p>
      A typical home piano should be tuned twice a year. A piano located in a
      church or school may get more use and abuse requiring more frequent
      tuning. Pianos used for performances, concerts or recording should be
      tuned before each session.
    </p>

    <h2> Why do pianos go out of tune?</h2>

    <p>
      There a several factors which cause a piano to go out of tune. First and
      foremost, is the weather. Specifically, changes in temperature and
      humidity cause the wood in your piano to shrink and swell resulting in it
      going out of tune. For this reason, pianos will typically go sharp in the
      summer and flat in the winter. Normal wear and tear from playing can also
      cause a piano to go slightly out. Additionally, there may be structural
      problems like a bad pin block resulting in loose tuning pins. This can
      cause your piano to go way out of tune and fast! If your tuner mentions
      these problems, ask him or her – they usually delight in sharing the
      knowledge of your piano. They want you to understand what is happening.
    </p>

    <h2>What is the biggest threat to my piano?</h2>

    <p>
      The biggest threat to most pianos is exposure to extreme moisture and
      dryness. This can negatively affect tuning stability, playability, and
      greatly decrease life expectancy of your instrument. For this reason,
      pianos should not be placed on top of air vents, near fireplaces/wood
      stoves, or in direct sunlight. The best thing is to create a stable
      climate for your piano with relative humidity right around 45%. If a piano
      is located in an area where the climate cannot be controlled, ask your
      technician about having a Piano Life Saver System installed. This is a
      piano humidity control system designed to protect against fluctuating
      humidity levels inside your piano.
    </p>

    <h2>What else is there to piano maintenance aside from regular tuning?</h2>

    <p>
      There is more to piano maintenance than just tuning. All pianos from time
      to time will need action regulation, voicing and cleaning. If your piano
      has a climate control system, it will require attention. Adding water, pad
      treatment and pad replacement will extend the effectiveness and life of
      your system. It is protecting your piano, make sure it can do it’s job
      efficiently.
    </p>

    <h2>What is action regulation?</h2>

    <p>
      Action regulation is a detailed and time consuming process of restoring
      the thousands of action parts back to their factory specifications. With
      normal wear and tear, the individual action parts will become loose, worn
      and misaligned. Wood parts can warp and felts will wear. Springs become
      weak and hammers and keys uneven. You may experience lots of squeaking/
      clicking sounds and the keyboard feels uneven to the touch. Some keys may
      be hard to depress and other notes won’t sound at all. This can be very
      frustrating and make it impossible to play with expression and dynamics.
      It’s time to regulate your piano! With the piano regulated properly, now
      you can concentrate on making music rather than on which 'problem' notes
      to avoid.
    </p>

    <h2>What is voicing?</h2>

    <p>
      Voicing a piano is when the technician works with the hammer felts to
      alter the tone. A piano maybe loud, tinny, brittle, soft, dull, dark etc.
      By cleaning, re-shaping, needling and sometimes applying hammer hardening
      or softening solution, a pianos tone can be changed (to a degree) to suit
      the individual taste of a player. Often a few notes on the piano will
      stand out as louder than others or a section of the keyboard will sound a
      little dull. Through careful voicing a technician can even out the tone
      across the keyboard giving it a more consistent sound. Before a serious
      attempt can be made at voicing, the piano must be well regulated and
      perfectly tuned.
    </p>

    <h2>How did you get into piano tuning?</h2>

    <p>
      After graduating High School in 2000, I began taking piano lessons to
      prepare for becoming a music major in college. I was interested in
      pursuing a career as a professional guitarist and getting into some kind
      of instrument repair. While taking piano lessons, I was offered a job as
      an apprentice where I would learn the in’s and out’s of the piano tuning,
      repair and rebuilding business. Due to the role music played in my life
      and that I enjoyed working with my hands and fixing things, this turned
      out to be the perfect line of work for me. Its been nearly 20 years since
      I began work in this field and I can’t imagine doing anything else.
    </p>
  </Layout>
)

export default IndexPage
